import React, { Component } from "react";
import { Link } from "react-router-dom";
import Image from "image/rob.png";
import { logException } from "helpers/sentry";
import FourZeroFourStyleWrapper from "./style";
import translateMsg from "helpers/translateMsg";
import { withTranslation } from 'react-i18next';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    const e = {
      error: error,
      info: info
    };
    console.log(error);
    logException(JSON.stringify(e));
  }

  render() {
    const { hasError } = this.state;

    if (hasError === false) {
      return this.props.children;
    }
    return (
      <FourZeroFourStyleWrapper className="iso404Page">
        <div className="iso404Content">
          <h2>
            {translateMsg(this.props.t, 'back', 'back_ui_error_title')}
          </h2>
          <h3>
            {translateMsg(this.props.t, 'back', 'back_ui_error_subtitle')}
          </h3>

          <button type="button">
            <a href="/">
              {translateMsg(this.props.t, 'back', 'back_ui_back_home')}
            </a>
          </button>
        </div>

        <div className="iso404Artwork">
          <img alt="#" src={Image} />
        </div>
      </FourZeroFourStyleWrapper>
    );
  }
}

export default (withTranslation()(App));