
const options = [

    /*
  {
    key: 'api',
    label: 'Api',
    leftIcon: 'ion-android-menu',
  },
  {
    key: 'qrcode',
    label: 'Qrcode',
    leftIcon: 'ion-android-menu',
  },

*/

  /*
  {
    key: 'ecommerce',
    label: 'sidebar.ecommerce',
    leftIcon: 'ion-bag',
    children: [
      {
        key: 'shop',
        label: 'sidebar.shop',
      },
      {
        key: 'cart',
        label: 'sidebar.cart',
      },
      {
        key: 'checkout',
        label: 'sidebar.checkout',
      },
      {
        key: 'card',
        label: 'sidebar.cards',
      },
    ],
  },
*/

];
export default options;
