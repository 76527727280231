import antdSA from 'antd/lib/locale-provider/ru_RU';
import appLocaleData from 'react-intl/locale-data/ru';
import saMessages from '../locales/ru_RU.json';

const saLang = {
  messages: {
    ...saMessages
  },
  antd: antdSA,
  locale: 'ru-RU',
  data: appLocaleData
};
export default saLang;
