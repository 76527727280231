import { init, log } from './sentry';



export const initSentry = () => {
  //if (process.env.NODE_ENV === 'production') {
  init();
  //}
}


export const logException = (error) => {
  log(error);
  /*
  if (process.env.NODE_ENV === 'production') {
      log(error);
  } else {
      console.error(error);
  }
  */
}


