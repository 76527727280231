import { SET_SOCIALS } from "../restaurant/constants";

const initState = [];

export default function regimeReducer(state = initState, action) {
  switch (action.type) {
    case SET_SOCIALS:
      return action.payload;

    default:
      return state;
  }
}
