const initState = "fr";

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case "CHANGE_LANGUAGE":
      return action.language.locale;

    default:
      return state;
  }
  return state;
}
