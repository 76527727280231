import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import reducers from "../redux/reducers";
import rootSaga from "../redux/sagas";

import { persistStore, persistReducer } from "redux-persist"; //cd
import storage from "redux-persist/lib/storage"; //cd

const persistConfig = {
  //cd
  key: "root",
  storage,
  //whitelist: ["Auth", "LanguageSwitcher", "User", "lang"]
  whitelist: ["LanguageSwitcher", "User", "lang"]
  //whitelist: ['Auth', 'Restaurant', 'User']
  //blacklist: ['location', 'match']
};

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;

const rootReducers = combineReducers({
  ...reducers,
  router: connectRouter(history)
});
const persistedReducer = persistReducer(persistConfig, rootReducers); //cd

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

//window.store = store;

let persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export { persistor, store, history };
