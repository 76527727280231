import {
  SET_RESTAURANT_PRODUCTS,
  ADD_RESTAURANT_PRODUCT,
  DELETE_RESTAURANT_PRODUCT,
  UPDATE_RESTAURANT_PRODUCT,
} from "../restaurant/constants";
import update from "immutability-helper";

const initState = [];

export default function restaurantProductReducer(state = initState, action) {
  switch (action.type) {
    case SET_RESTAURANT_PRODUCTS:
      return action.payload;
    case DELETE_RESTAURANT_PRODUCT: {


      const index = state.findIndex(
        item => item.uuid === action.payload
      );


      if (index !== -1) {
        return update(state, {
          $splice: [[index, 1]]
        }
        );
      }

      return state;
    }
    case ADD_RESTAURANT_PRODUCT: {

      return update(state, {
        $push: [action.payload]
      });
    }

    case UPDATE_RESTAURANT_PRODUCT: {

      console.log('UPDATE_RESTAURANT_PRODUCT');
      console.log(action.payload);
      console.log(action.payload.uuid);
      const index = state.findIndex(
        item => item.uuid === action.payload.uuid
      );
      if (index !== -1) {
        return update(state, {
          [index]: { $set: action.payload }
        }
        );
      }

      return state;
    }
    default:
      return state;
  }
}
