export const getLang = () => {


    if(localStorage.getItem('lang') !== null) {
        return localStorage.getItem('lang');
    }
    return 'fr';

};

export default getLang 
