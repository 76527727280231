import Auth from "./auth/reducer";
import App from "./app/reducer";
import User from "./user/reducer";

import OrderStatus from "./orderStatus/reducer";
import Country from "./country/reducer";
import Tax from "./tax/reducer";
import Station from "./station/reducer";
import Regime from "./regime/reducer";
import Payment from "./payment/reducer";
import Social from "./social/reducer";
import Service from "./service/reducer";
import SpecialityFood from "./specialityFood/reducer";


import CashRegister from "./cashregister/reducer";
import DeliveryPartner from "./deliveryPartner/reducer";
import LanguageSwitcher from "./languageSwitcher/reducer";
import ThemeSwitcher from "./themeSwitcher/reducer";


import Firestore from "./firestore/reducer";

import RestaurantAttribute from "./restaurantAttribute/reducer";
import RestaurantInfo from "./restaurantInfo/reducer";
import RestaurantFilterProductAttribute from "./restaurantFilterproductattribute/reducer";
import RestaurantLanguage from "./restaurantLanguage/reducer";
import RestaurantMenu from "./restaurantMenu/reducer";
import RestaurantPricing from "./restaurantPricing/reducer";
import RestaurantProduct from "./restaurantProduct/reducer";
import RestaurantPromocode from "./restaurantPromocode/reducer";
import RestaurantRegime from "./restaurantRegime/reducer";
import RestaurantPayment from "./restaurantPayment/reducer";

import RestaurantOption from "./restaurantOption/reducer";
import RestaurantShowtime from "./restaurantShowtime/reducer";

import lang from "./lang/reducer";
import userLanguage from "./userLanguage/reducer";

export default {
  Auth,
  App,
  User,
  CashRegister,
  DeliveryPartner,
  Payment,
  Station,
  Country,
  OrderStatus,
  Tax,
  Regime,
  Social,
  Service,
  SpecialityFood,
  LanguageSwitcher,
  ThemeSwitcher,
  Firestore,
  RestaurantAttribute,
  RestaurantFilterProductAttribute,
  RestaurantInfo,
  RestaurantOption,
  RestaurantPricing,
  RestaurantRegime,
  RestaurantPayment,
  RestaurantLanguage,
  RestaurantPromocode,
  RestaurantProduct,
  RestaurantShowtime,
  RestaurantMenu,
  lang,
  userLanguage

};
