import React from "react";
import { Modal, Button } from "antd";
import IntlMessages from "components/utility/intlMessages";

function App(props) {
  const handleOk = (e) => {
    console.log(e);
    props.update(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    props.update(false);
  };

  return false;


}

export default App;
