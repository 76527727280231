import actions from "./actions";

const initState = {};

export default function firestoreReducer(state = initState, action) {
  switch (action.type) {
    case actions.FIRESTORE:

      return action.payload;

    default:
      return state;
  }
}
