let baseUrl = 'https://api.foound.com';
let apiUrl = 'https://api.foound.com/api/v1';
let client = 'general';

// let baseUrl = 'https://backpack.foound.com';
// let apiUrl = 'https://backpack.foound.com/api/v1';


//let baseUrl = 'http://backpack-foound.au';
//let apiUrl = 'http://backpack-foound.au/api/v1';


let jsonFolder = 'staticV4';

let qrcodeUrl = 'https://qrcode.foound.com';


const siteConfig = {
  siteName: "Foound",
  siteIcon: "ion-flash",
  footerText: "copyright ©️ Foound 2019-2022"
};


if (process.env.REACT_APP_CLIENT && process.env.REACT_APP_CLIENT === 'elior') {
  baseUrl = 'https://api.elior.app';
  apiUrl = 'https://api.elior.app/api/v1';
  qrcodeUrl = 'https://api.elior.app';
  siteConfig.siteName = 'Elior.app';
  siteConfig.footerText = "copyright ©️ Elior 2019-2022";
  client = 'elior';
} else if (process.env.REACT_APP_CLIENT && process.env.REACT_APP_CLIENT === 'eliorpreprod') {
  baseUrl = 'https://apielior.foound.com';
  apiUrl = 'https://apielior.foound.com/api/v1';
  qrcodeUrl = 'https://apielior.foound.com';
  siteConfig.siteName = 'Elior.app';
  siteConfig.footerText = "copyright ©️ Elior preprod 2019-2022";
  client = 'elior';
}

console.log('API_URL');
console.log(apiUrl);
console.log(siteConfig.siteName);
console.log(client);

const assetsUrl = "https://assets.foound.io";
const imgUrl = "https://img.foound.io";


const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault"
};
const language = "french";
const AlgoliaSearchConfig = {
  appId: "",
  apiKey: ""
};

const firebaseConfig = {
  apiKey: "AIzaSyDUnyWH7xV3bjwNNCbGmFTGRlWcE-AlhpU",
  projectId: "foound",
  //authDomain: "<YOUR-AUTH-DOMAIN>",
  databaseURL: "https://foound.firebaseio.com",
  storageBucket: "foound.appspot.com"
};
const googleConfig = {
  apiKey: "" //
};
const mapboxConfig = {
  tileLayer: "",
  maxZoom: "",
  defaultZoom: "",
  center: []
};
const youtubeSearchApi = "";
export {
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi,
  baseUrl,
  apiUrl,
  qrcodeUrl,
  imgUrl,
  assetsUrl,
  jsonFolder,
  client
};
