import { SET_DELIVERY_PARTNER } from "../restaurant/constants";

const initState = [];

export default function deliveryReducer(state = initState, action) {
  switch (action.type) {
    case SET_DELIVERY_PARTNER:
      return action.payload;

    default:
      return state;
  }
}
