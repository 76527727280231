import { all, call, put, select, takeEvery, fork } from "redux-saga/effects";

import actionsLang from "../languageSwitcher/actions";
import actionsUser from "../user/actions";
import actionsAuth from "../auth/actions";

import isInApp from "../../helpers/isInApp";

import {
  INIT,
  LOAD_RESTAURANT_INFO,
  SET_REGIMES,
  SET_ORDER_STATUS,
  SET_PAYMENTS,
  SET_SOCIALS,
  SET_SERVICES,
  SET_SPECIALITY_FOODS,
  SET_CASH_REGISTER,
  SET_DELIVERY_PARTNER,
  UPLOAD_LOGO,
  UPLOAD_IMG_LAYOUT,
  UPLOAD_IMG_CATALOG,
  SET_RESTAURANT_FILTER_PRODUCT_ATTRIBUTES,
  SET_RESTAURANT_PRODUCTS,
  SET_RESTAURANT_SHOWTIMES,
  SET_STATIONS,
  SET_RESTAURANT_INFO,
  SET_RESTAURANT_MENUS,
  SET_RESTAURANT_OPTIONS,
  SET_RESTAURANT_PRICINGS,
  SET_RESTAURANT_PROMOCODES,
  SET_RESTAURANT_ATTRIBUTES,
  SET_RESTAURANT_LANGUAGES,
  SET_RESTAURANT_REGIMES,
  SET_RESTAURANT_PAYMENTS,
  SET_COUNTRY,
  SET_TAX,
  SET_USER_LANGUAGE
} from "./constants";
import { push } from "react-router-redux";
import request from "helpers/request";
import getCurrentRestaurantUuid from "helpers/getCurrentRestaurantUuid";
import queryString from "query-string";

const getRestaurant = state => state.Restaurant;
const getLanguageSwitcher = state => state.LanguageSwitcher;
const getRouter = state => state.router;

window.fooundinit = false;


export function* init() {
  yield takeEvery(INIT, function* () {

    console.log('INIT');
    console.log(window.fooundinit);


    const parsed = queryString.parse(document.location.search);

    console.log(parsed);
    const router = yield select(getRouter);
    //console.log(8888888812);
    //console.log(router.location.pathname);
    //console.log(document.location.pathname);



    if (window.fooundinit === false && document.location.pathname !== '/fromApp') {
      yield put({ type: LOAD_RESTAURANT_INFO });

      if (getCurrentRestaurantUuid()) {

      } else {
        // document.location.href = "/dashboard/setting";



        if (router.location.pathname === '/reset_password' || router.location.pathname === 'forgotpassword') {

        } else {
          yield put(push("/dashboard/setting"));
        }


      }

      yield put({ type: "APP_READY" });
      window.fooundinit = true;
    }


  });
}



export function* loadRestaurantData() {
  yield takeEvery(LOAD_RESTAURANT_INFO, function* () {


    console.log('LOAD_RESTAURANT_INFO');

    const languageSwitcher = yield select(getLanguageSwitcher);

    console.log(6666666);
    console.log(languageSwitcher.language.locale);
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", languageSwitcher.language.locale);
    }


    if (getCurrentRestaurantUuid()) {


      //const client = yield call(request.get, `/restaurant/${getCurrentRestaurantUuid()}/client`);

      const client = yield call(request.getPublic, `/client/${getCurrentRestaurantUuid()}/client`);

      console.log('CLIENT API');
      console.log(client);

      if (client && client.data) {


        yield put({ type: SET_COUNTRY, payload: client.data.country });
        yield put({ type: SET_TAX, payload: client.data.tax });


        yield put({ type: SET_ORDER_STATUS, payload: client.data.orderStatus });
        yield put({ type: SET_REGIMES, payload: client.data.regimes });
        yield put({ type: SET_PAYMENTS, payload: client.data.payments });
        yield put({ type: SET_SOCIALS, payload: client.data.socials });
        yield put({ type: SET_SERVICES, payload: client.data.services });
        yield put({ type: SET_SPECIALITY_FOODS, payload: client.data.specialityFoods });
        yield put({ type: SET_CASH_REGISTER, payload: client.data.cashRegister });
        yield put({ type: SET_DELIVERY_PARTNER, payload: client.data.deliveryPartner });
        yield put({ type: SET_STATIONS, payload: client.data.stations });

        yield put({ type: SET_RESTAURANT_PRODUCTS, payload: client.data.restaurantProduct });
        yield put({ type: SET_RESTAURANT_FILTER_PRODUCT_ATTRIBUTES, payload: client.data.restaurantFilteredProductAttribute });
        yield put({ type: SET_RESTAURANT_MENUS, payload: client.data.restaurantMenu });
        yield put({ type: SET_RESTAURANT_SHOWTIMES, payload: client.data.restaurantshowtime });
        yield put({ type: SET_RESTAURANT_PROMOCODES, payload: client.data.restaurantPromocode });
        yield put({ type: SET_RESTAURANT_PRICINGS, payload: client.data.restaurantPricing });
        yield put({ type: SET_RESTAURANT_ATTRIBUTES, payload: client.data.restaurantAttribute });
        yield put({ type: SET_RESTAURANT_LANGUAGES, payload: client.data.restaurantLanguage });
        yield put({ type: SET_RESTAURANT_REGIMES, payload: client.data.restaurantRegime });
        yield put({ type: SET_RESTAURANT_PAYMENTS, payload: client.data.restaurantPayment })
        yield put({ type: SET_RESTAURANT_OPTIONS, payload: client.data.restaurantOption });
        yield put({ type: SET_RESTAURANT_INFO, payload: client.data.restaurantInfo });
        yield put({ type: SET_USER_LANGUAGE, payload: client.data.userLanguagesV2 });
      }
    }


  });
}

export function* changeLang() {
  yield takeEvery("CHANGE_LANGUAGE", function* (payload) {

    yield localStorage.setItem("lang", payload.language.locale);

    //yield put(actionsLang.changeLanguageSuccess());
  });
}



export function* uploadImgLayout() {
  yield takeEvery(UPLOAD_IMG_LAYOUT, function* (payload) {

    const result = yield call(
      request.upload,
      `/restaurant/${getCurrentRestaurantUuid()}/img_layout`,
      payload.payload
    );

    const restaurantInfo = yield call(request.get, `/restaurant/${getCurrentRestaurantUuid()}`);
    yield put({ type: SET_RESTAURANT_INFO, payload: restaurantInfo.data });

  });
}

export function* uploadImgCatalog() {
  yield takeEvery(UPLOAD_IMG_CATALOG, function* (payload) {

    const result = yield call(
      request.upload,
      `/restaurant/${getCurrentRestaurantUuid()}/img_catalog`,
      payload.payload
    );

    const restaurantInfo = yield call(request.get, `/restaurant/${getCurrentRestaurantUuid()}`);
    yield put({ type: SET_RESTAURANT_INFO, payload: restaurantInfo.data });

  });
}

export default function* rootSaga() {
  yield all([
    fork(init),
    fork(loadRestaurantData),
    fork(changeLang),
    //fork(changeLangSuccess),
    fork(uploadImgLayout),
    fork(uploadImgCatalog)
  ]);
}
