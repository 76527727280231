import { SET_RESTAURANT_ATTRIBUTES } from "../restaurant/constants";

const initState = [];

export default function restaurantAttributeReducer(state = initState, action) {
  switch (action.type) {
    case SET_RESTAURANT_ATTRIBUTES:

      return action.payload;

    default:
      return state;
  }
}
