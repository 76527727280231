import de from './de_DE';
import en from './en_US';
import es from './es_ES';
import fr from './fr_FR';
import it from './it_IT';
import nl from './nl_NL';
import ru from './ru_RU';
import hu from './hu_HU';
import ja from './ja_JA';
import my from './my_MY';

const messages = {
  de: de,
  en: en,
  es: es,
  fr: fr,
  hu: hu,
  it: it,
  nl: nl,
  ru: ru,
  ja: ja,
  my: my
};

export default messages;
