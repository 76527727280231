import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ErrorBoundary from 'components/ErrorBoundary';
import { connect } from 'react-redux';
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <BrowserRouter>
        <Route
          exact
          path={'/'}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <Route
          exact
          path={'/404'}
          component={asyncComponent(() => import('./containers/Page/404'))}
        />
        <Route
          exact
          path={'/500'}
          component={asyncComponent(() => import('./containers/Page/500'))}
        />
        <Route
          exact
          path={'/signin'}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <Route
          exact
          path={'/signup'}
          component={asyncComponent(() => import('./containers/Page/signup'))}
        />
        <Route
          exact
          path={'/forgotpassword'}
          component={asyncComponent(() =>
            import('./containers/Page/forgotPassword')
          )}
        />

        <Route
          exact
          path={'/reset_password'}
          component={asyncComponent(() =>
            import('./containers/Page/resetPassword')
          )}
        />

        <Route
          exact
          path={'/resetpassword/:token'}
          component={asyncComponent(() =>
            import('./containers/Page/resetPassword')
          )}
        />


        <Route
          exact
          path={'/fromApp'}
          component={asyncComponent(() =>
            import('./containers/FromAppPage')
          )}
        />


        <Route
          exact={false}
          path={'/deeplink/order/:orderUuid/:restaurantUuid'}
          component={asyncComponent(() =>
            import('./containers/Deeplink/Order')
          )}
        />

        <Route
          exact={false}
          path={'/deeplink/booking/:bookingUuid/:restaurantUuid'}
          component={asyncComponent(() =>
            import('./containers/Deeplink/Booking')
          )}
        />




        <RestrictedRoute
          path="/dashboard"
          component={App}
          isLoggedIn={isLoggedIn}
        />
      </BrowserRouter>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);
