import * as Sentry from '@sentry/browser';


export const init = () => {
    Sentry.init({
        dsn: "https://97924a1355154b3fa6a3a157ee9212b8@sentry.io/1523215"
    });
}

export const log = (error) => {
    Sentry.captureException(error);
}



