import axios from "axios";
import { apiUrl, jsonFolder } from "../settings";
import { message, notification } from "antd";
import { logException } from "helpers/sentry";
import getLang from "helpers/getLang";
import IntlMessages from 'components/utility/intlMessages';
import { FormattedMessage } from 'react-intl';
import getCurrentRestaurantUuid from "helpers/getCurrentRestaurantUuid";
import getDomain from "helpers/getDomain";
import { includes } from "underscore";

import getMessage from 'helpers/getMessage';



const forget = async payload => {

  const url = `${apiUrl}/forget?lang=${getLang()}`;
  const formdata = new FormData();
  if (payload.email) {
    formdata.append("email", payload.email);
  }

  //formdata.append("lang", 'en');

  return axios({
    method: "post",
    url: url,
    data: formdata,
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
  })
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        message.error(getMessage('email no exist', getLang()));
        return false;
        //throw new Error(response.statusText);
      }
      return response.data;
    })
    .then(data => {

      message.success(getMessage('email has sent', getLang()));
      return data;
    })
    .catch(err => {
      message.error(getMessage('email no exist', getLang()));
      logException(JSON.stringify(err));
      return false;
    });
};

const reset = async payload => {


  const url = `${apiUrl}/reset`;
  const formdata = new FormData();
  if (payload.token) {
    formdata.append("token", payload.token);
  }

  if (payload.email) {
    formdata.append("email", payload.email);
  }

  if (payload.password) {
    formdata.append("password", payload.password);
  }

  if (payload.confirm) {
    formdata.append("password_confirmation", payload.confirm);
  }

  return axios({
    method: "post",
    url: url,
    data: formdata,
    config: {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  })
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        message.error(getMessage('email no exist', getLang()));
        return false;
        //throw new Error(response.statusText);
      }
      return response.data;
    })
    .then(data => {


      message.success(getMessage('Password change success', getLang()));
      return data;
    })
    .catch(err => {
      message.error("Password change error");
      logException(JSON.stringify(err));
      return false;
    });
};



const login = async payload => {
  const url = `${apiUrl}/clientLogin`;
  const formdata = new FormData();



  if (payload.username) {
    formdata.append("email", payload.username);
  }

  if (payload.password) {
    formdata.append("password", payload.password);
  }

  if (payload.gg_token) {
    formdata.append("gg_token", payload.gg_token);
  }


  return axios({
    method: "post",
    url: url,
    data: formdata,
    config: {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  })
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        //message.error('fail login');

        notification["error"]({
          //message: "",
          message: getMessage('mail or password incorect', getLang()),
          description: ""
        });
        return false;
        //throw new Error(response.statusText);
      }
      return response.data;
    })
    .then((data) => {
      return data.access_token;
    })
    .catch(err => {

      if (JSON.stringify(err).includes('403')) {
        notification["error"]({
          message: getMessage('Not authorized account', getLang()),
          description: ""
        });
      } else {
        notification["error"]({
          message: getMessage('mail or password incorect', getLang()),
          description: ""
        });
      }


      //message.error('mail or password incorect');
      console.log(err);
      logException(JSON.stringify(err));
      return false;
    });
};

const getUser = async () => {
  const token = await localStorage.getItem("id_token");
  const url11 = `${apiUrl}/user`;

  if (!token) {
    return false;
  }

  const options = {
    method: "get",
    headers: new Headers({
      Authorization: "Bearer " + token,
      Accept: "application/json"
    })
  };

  try {
    const response = await fetch(`${apiUrl}/user`, options);


    //console.log(666666);
    //console.log(document.location);

    if (response.status !== 200) {
      notification["error"]({ message: "fail to fetch user", description: "" });
      logException("fail to fetch user");

      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      if (document.location.pathname !== '/') {

        document.location.href = "/";
      }

      return false;
    }
    const aa = await response.json();


    return aa;
  } catch (err) {
    console.log(err);
    notification["error"]({ message: "fail to fetch user", description: "" });
    logException(JSON.stringify(err));

    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    if (document.location.pathname !== '/') {

      document.location.href = "/";
    }
    return false;
  }
};

const get = async path => {
  const token = await localStorage.getItem("id_token");
  const url = `${apiUrl}${path}?lang=${getLang()}`;
  if (!token) {
    return false;
  }



  const options = {
    method: "get",
    headers: new Headers({
      Authorization: "Bearer " + token,
      Accept: "application/json"
    })
  };

  const response = await fetch(url, options);
  return await response.json();
};

const put = async (path, params) => {
  const token = await localStorage.getItem("id_token");
  const url = `${apiUrl}${path}`;

  if (!token) {
    return false;
  }



  const options = {
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    body: JSON.stringify({
      ...params,
      lang: getLang(),
      commiter: window.fooundCommiter
    })
  };

  try {
    const response = await fetch(url, options);
    return await response.json();
  } catch (err) {
    console.log(err);
    notification["error"]({
      message: "error",
      description: JSON.stringify(err)
    });
    logException(JSON.stringify(err));
    return false;
  }
};

const post = async (path, params) => {
  const token = await localStorage.getItem("id_token");
  const url = `${apiUrl}${path}`;

  if (!token) {
    return false;
  }



  const options = {
    method: "post",
    headers: {
      Accept: "application/json",
      // Accept: "application/json", "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    body: JSON.stringify({
      ...params,
      lang: getLang(),
      commiter: window.fooundCommiter
    })
  };

  try {
    const response = await fetch(url, options);
    return await response.json();
  } catch (err) {
    console.log(err);
    notification["error"]({
      message: "error",
      description: JSON.stringify(err)
    });
    logException(JSON.stringify(err));
    return false;
  }
};

const upload = async (path, params) => {
  const token = await localStorage.getItem("id_token");
  const url = `${apiUrl}${path}`;

  if (!token) {
    return false;
  }

  params.commiter = window.fooundCommiter;

  const options = {
    method: "post",
    headers: {
      // Accept: "application/json", "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    //body: file,
    body: JSON.stringify(params)
  };



  try {
    const response = await fetch(url, options);
    return await response.json();
  } catch (err) {

    notification["error"]({
      message: "error",
      description: JSON.stringify(err)
    });
    logException(JSON.stringify(err));
    return false;
  }
};

const destroy = async path => {
  const token = await localStorage.getItem("id_token");
  const url = `${apiUrl}${path}`;

  if (!token) {
    return false;
  }


  const options = {
    method: "delete",
    headers: {
      Authorization: "Bearer " + token
    }
  };

  try {
    const response = await fetch(url, options);
    return await response.json();
  } catch (err) {
    console.log(err);
    notification["error"]({
      message: "error",
      description: JSON.stringify(err)
    });
    logException(JSON.stringify(err));
    return false;
  }
};

const getPublic = async path => {
  const url = `${apiUrl}${path}`;

  const options = {
    method: "get"
  };

  try {
    const response = await fetch(url, options);
    return await response.json();
  } catch (err) {
    console.log(err);
    notification["error"]({
      message: "error",
      description: JSON.stringify(err)
    });
    logException(JSON.stringify(err));
    return false;
  }
};

export default {
  getUser,
  forget,
  reset,
  login,
  get,
  put,
  post,
  upload,
  destroy,
  getPublic,
};
