import { SET_SPECIALITY_FOODS } from "../restaurant/constants";

const initState = [];

export default function specialityFoodReducer(state = initState, action) {
  switch (action.type) {
    case SET_SPECIALITY_FOODS:
      return action.payload;

    default:
      return state;
  }
}
