import { SET_RESTAURANT_INFO, UPDATE_RESTAURANT_INFO } from "../restaurant/constants";

const initState = {};

export default function restaurantInfoReducer(state = initState, action) {
  switch (action.type) {
    case SET_RESTAURANT_INFO:
      return action.payload;
    case UPDATE_RESTAURANT_INFO: {
      return action.payload;
    }

    default:
      return state;
  }
}
