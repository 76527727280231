export const colors = {
  blue: {
    deep: "rgb(0, 121, 191)",
    light: "lightblue",
    lighter: "#d9fcff",
    soft: "#E6FCFF"
  },
  black: "#4d4d4d",
  shadow: "rgba(0,0,0,0.2)",
  grey: {
    darker: "#C1C7D0",
    dark: "#E2E4E6",
    medium: "#DFE1E5",
    N30: "#EBECF0",
    light: "#F4F5F7"
  },
  green: "rgb(185, 244, 188)",
  white: "white",
  purple: "rebeccapurple",
  orange: "#FF991F"
};

export const grid = 8;

export const borderRadius = 2;

export const headerStyle = {
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#f1f3f6",
  marginBottom: 5,
  backgroundColor: "#fcfcfc",
};

export const WEBSITE_LANGUAGE = [
  "ar",
  "cpf",
  "de",
  "en",
  "es",
  "fr",
  "it",
  "ja",
  "nl",
  "ru",
  "zh"
];

export const PRODUCT = "product";
export const GARNISH = "garnish";
export const CATEGORY = "category";
export const FORMULA = "formula";
export const OPTION = "option";
export const STATICTEXT = "statictext";
export const INGREDIENT_ADDED = "ingredientAdded";
export const INGREDIENT_INCLUDED = "ingredientIncluded";

export const OPTION_VALUE_TYPE_FREE_TEXT = 1;
export const OPTION_VALUE_TYPE_INGREDIENT_ADDED = 2;
export const OPTION_VALUE_TYPE_INGREDIENT_INCLUDED = 3;
export const OPTION_VALUE_TYPE_PRODUCT = 4;

export const I18N_NS = ['universal', 'manager', 'back', 'webapp'];


