export default function translateMsg(fnc, key = 'manager', v) {

  const bb = localStorage.getItem("babel");

  if (typeof bb === 'string') {
    return `${key}:${v}`;
  }

  if (typeof fnc === 'function') {

    try {
      const l = `${key}:messages.${v}`;
      let txt = fnc(l);
      txt = txt.replace('<br />', ' ');
      return txt;
    } catch (err) {
      return '';
    }

  }

  return '';

}
