import Delang from './entries/de_DE';
import Enlang from './entries/en-US';
import Zhlang from './entries/zh-Hans-CN';
import Salang from './entries/ar_SA';
import Itlang from './entries/it_IT';
import Eslang from './entries/es_ES';
import Frlang from './entries/fr_FR';
import NLlang from './entries/nl_NL';
import Rulang from './entries/ru_RU';
import Hulang from './entries/hu_HU';
import Jalang from './entries/ja_JA';
import Mylang from './entries/my_MY';

import { addLocaleData } from 'react-intl';

const AppLocale = {
  de: Delang,
  en: Enlang,
  zh: Zhlang,
  sa: Salang,
  it: Itlang,
  es: Eslang,
  fr: Frlang,
  nl: NLlang,
  ru: Rulang,
  hu: Hulang,
  ja: Jalang,
  my: Mylang
};
addLocaleData(AppLocale.de.data);
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.zh.data);
addLocaleData(AppLocale.sa.data);
addLocaleData(AppLocale.it.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.fr.data);
addLocaleData(AppLocale.ru.data);
addLocaleData(AppLocale.nl.data);
addLocaleData(AppLocale.hu.data);
addLocaleData(AppLocale.ja.data);
addLocaleData(AppLocale.my.data);

export default AppLocale;
