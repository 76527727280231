import messages from '../languageProvider/locales';

export default function getMessage(id, lang = 'fr') {

  //console.log(1111111);
  //console.log(id);
  //console.log(lang);

  if (messages[lang] && messages[lang][id]) {
    return messages[lang][id];
  }
  return 'unknown';
}
