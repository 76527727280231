import { SET_STATIONS } from "../restaurant/constants";

const initState = [];

export default function stationReducer(state = initState, action) {
  switch (action.type) {
    case SET_STATIONS:

      return action.payload;

    default:
      return state;
  }
}
