import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
//import AsyncStoragePlugin from 'i18next-react-native-async-storage';

import { I18N_NS } from './settings/constants';


async function initI18n() {

  console.log(111111111);

  let lang = 'fr';

  const l = localStorage.getItem("lang");
  if (l) {
    lang = l;
  }

  if (lang === 'my') {
    lang = 'ms';
  }




  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    //.use(AsyncStoragePlugin())
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      lng: lang,
      backend: {
        /* translation file path */
        loadPath: 'https://assets.foound.io/i18n/{{ns}}/{{lng}}.json'
      },
      fallbackLng: 'en',
      debug: true,
      /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
      ns: I18N_NS,
      defaultNS: 'back',
      //keySeparator: false,
      interpolation: {
        escapeValue: false,
        formatSeparator: ','
      },
      react: {
        useSuspense: true
      }
    });


  console.log(222222222);

}

initI18n();


export default i18n;