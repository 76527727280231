import antdSA from 'antd/lib/locale-provider/hu_HU';
import appLocaleData from 'react-intl/locale-data/hu';
import saMessages from '../locales/hu_HU.json';

const saLang = {
  messages: {
    ...saMessages
  },
  antd: antdSA,
  locale: 'hu-HU',
  data: appLocaleData
};
export default saLang;
