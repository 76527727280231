export const getInitials = (str) => {


  console.log(222222);
  console.log(str);
  console.log(typeof str);

  if (str && typeof str === 'string' && str.length > 1) {
    const matches = str.match(/\b(\w)/g);
    return matches.join('');
  }
  return '';


};

export default getInitials
