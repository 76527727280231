import React, { useState, useEffect } from 'react';
import { Provider } from "react-redux";
import { store, history, persistor } from "./redux/store";
import PublicRoutes from "./router";
import { ThemeProvider } from "styled-components";
import { LocaleProvider, ConfigProvider } from "antd";
import frFR from 'antd/es/locale/fr_FR';
import enUS from 'antd/es/locale/en_US';
import { IntlProvider } from "react-intl";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import config, {
  getCurrentLanguage
} from "./containers/LanguageSwitcher/config";
import { themeConfig } from "./settings";
import AppStyle from "./AppStyle";
import Boot from "./redux/boot";
import GlobalStyles from "./static/style/globalStyle";
import actions from "redux/restaurant/actions";
import actionsFirestore from "redux/firestore/actions";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { notification } from "components/index";

import { versionId } from '../package.json';
import request from "helpers/request";
import NewMaj from "./components/NewMaj";
import queryString from "query-string";

import i18n from './i18n';

import { useTranslation } from 'react-i18next';
import { I18N_NS } from './settings/constants';

window.fooundCommiter = Date.now();

let currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];
const parsed = queryString.parse(document.location.search);

if (parsed.lang && parsed.lang === "en") {
  currentAppLocale = 'en';
}

if (parsed.lang && parsed.lang === "fr") {
  currentAppLocale = 'fr';
}














store.dispatch(actions.init());

function DashApp(props) {

  const [maj, setMaj] = useState(false);

  const { t, i18n, ready } = useTranslation(I18N_NS, { useSuspense: false });

  if (!ready) {
    return (
      <div>loading...</div>
    )
  }


  window.setInterval(() => {
    _checkVersion();
  }, 30000);

  const _checkVersion = async () => {

    //console.log('_checkVersion');

    const result = await request.get('/version/client');
    //console.log(result);

    if (result && result.result && result.result === 'ok' && result.version) {
      //console.log(+result.version);
      //console.log(versionId);

      if (+result.version !== versionId && maj === false) {
        setMaj(true);
      }
    }

  }

  return (
    <LocaleProvider locale={currentAppLocale.antd}>

      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ConfigProvider locale={frFR}>
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <AppStyle>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <PublicRoutes history={history} />
                  <NewMaj maj={maj} update={(v) => setMaj(v)} />
                </PersistGate>
              </Provider>
              <GlobalStyles />
            </AppStyle>
          </ThemeProvider>
        </ConfigProvider>
      </IntlProvider>
    </LocaleProvider>
  );

}


Sentry.init({
  dsn: "https://97924a1355154b3fa6a3a157ee9212b8@o170137.ingest.sentry.io/1523215",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


Boot()
  //.then(() => initSentry())
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;
export { AppLocale };
