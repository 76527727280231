import styled from 'styled-components';
import { palette, font } from 'styled-theme';

const DashAppHolder = styled.div`
  font-family: ${font('primary', 0)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }

  .ant-row:not(.ant-form-item) {
    ${'' /* margin-left: -8px;
    margin-right: -8px; */};
    &:before,
    &:after {
      display: none;
    }
  }

  .ant-row > div {
    padding: 0;
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }


  #editProduct_active.ant-switch-checked,
  #editProduct_available.ant-switch-checked,
  #editCategory_active.ant-switch-checked,
  #editCategory_available.ant-switch-checked,
  #editIngredient_active.ant-switch-checked,
  #editIngredient_available.ant-switch-checked,
  #editStatictext_active.ant-switch-checked,
  #editOptionValue_active.ant-switch-checked,
  #editOptionValue_inside_status.ant-switch-checked,
  #editOptionValue_delivery_status.ant-switch-checked,
  #editOptionValue_takeaway_status.ant-switch-checked,
  #createOption_active.ant-switch-checked,
  #editOption_inside_status.ant-switch-checked,
  #editOption_takeaway_status.ant-switch-checked,
  #editOption_delivery_status.ant-switch-checked,


  #editSlide_masked.ant-switch-checked,




  #createOption_inside_status.ant-switch-checked,
  #createOption_takeaway_status.ant-switch-checked,
  #createOption_delivery_status.ant-switch-checked

  {
    background-color: red;
  }


`;

export default DashAppHolder;
