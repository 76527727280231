export const isInApp = () => {


  //return window.self !== window.top;


  if (sessionStorage.getItem('inApp')) {
    return true;
  }
  return false;

};

export default isInApp


