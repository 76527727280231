import actions from './actions';

const initState = {};

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_USER:

      console.log(333333);
      console.log(action.payload);
      return action.payload;
    default:
      return state;
  }
}
