import React, { Component } from "react";
import { Route } from "react-router-dom";
import asyncComponent from "helpers/AsyncFunc";

const routes = [
  {
    path: "",
    component: asyncComponent(() => import("../Dashboard"))
  },
  {
    path: "home",
    component: asyncComponent(() => import("../HomePage"))
  },

  {
    path: "homebuild",
    component: asyncComponent(() => import("../HomebuildPage"))
  },

  {
    path: "page",
    component: asyncComponent(() => import("../PagePage"))
  },

  {
    path: "page/create",
    component: asyncComponent(() => import("../PagePage/Create"))
  },

  {
    path: "page/edit/:pageUuid",
    component: asyncComponent(() => import("../PagePage/Edit"))
  },

  {
    path: "header",
    component: asyncComponent(() => import("../HeaderPage"))
  },

  {
    path: "campaign",
    component: asyncComponent(() => import("../CampaignPage"))
  },

  {
    path: "campaign/create",
    component: asyncComponent(() => import("../CampaignPage/Create"))
  },

  {
    path: "campaign/edit/:campaignUuid",
    component: asyncComponent(() => import("../CampaignPage/Edit"))
  },
  {
    path: "campaign/show/:campaignUuid",
    component: asyncComponent(() => import("../CampaignPage/Show"))
  },


  {
    path: "homebloc",
    component: asyncComponent(() => import("../HomeblocPage"))
  },

  {
    path: "homeblocV2",
    component: asyncComponent(() => import("../HomeblocV2Page"))
  },

  {
    path: "homeblocV2/create",
    component: asyncComponent(() => import("../HomeblocV2Page/Create"))
  },

  {
    path: "homeblocV2/edit/:homeblocId",
    component: asyncComponent(() => import("../HomeblocV2Page/Edit"))
  },


  {
    path: "admin/booking/:uuid",
    component: asyncComponent(() => import("../Admin/Booking"))
  },
  {
    path: "settingProduct",
    component: asyncComponent(() => import("../SettingProductPage"))
  },
  {
    path: "layout",
    component: asyncComponent(() => import("../LayoutPage"))
  },
  {
    path: "setting",
    component: asyncComponent(() => import("../SettingPage"))
  },
  {
    path: "information",
    component: asyncComponent(() => import("../InformationPage"))
  },
  {
    path: "communication",
    component: asyncComponent(() => import("../CommunicationPage"))
  },
  {
    path: "specialityFood",
    component: asyncComponent(() => import("../SpecialityFoodPage"))
  },
  {
    path: "service",
    component: asyncComponent(() => import("../ServicePage"))
  },
  {
    path: "serviceOrder",
    component: asyncComponent(() => import("../ServiceOrderPage"))
  },
  {
    path: "serviceAuthentification",
    component: asyncComponent(() => import("../ServiceAuthentificationPage"))
  },
  {
    path: "serviceTakeaway",
    component: asyncComponent(() => import("../ServiceTakeawayPage"))
  },
  {
    path: "serviceDelivery",
    component: asyncComponent(() => import("../ServiceDeliveryPage"))
  },
  {
    path: "serviceBooking",
    component: asyncComponent(() => import("../ServiceBookingPage"))
  },
  {
    path: "language",
    component: asyncComponent(() => import("../LanguagePage"))
  },
  {
    path: "address",
    component: asyncComponent(() => import("../AddressPage"))
  },
  {
    path: "regime",
    component: asyncComponent(() => import("../RegimePage"))
  },
  {
    path: "social",
    component: asyncComponent(() => import("../SocialPage"))
  },
  {
    path: "deliveryPartner",
    component: asyncComponent(() => import("../DeliveryPartnerPage"))
  },

  {
    path: "specialDate",
    component: asyncComponent(() => import("../SpecialDatePage"))
  },
  {
    path: "specialDate/edit/:uuid",
    component: asyncComponent(() => import("../SpecialDatePage/Edit"))
  },
  {
    path: "specialDate/create",
    component: asyncComponent(() => import("../SpecialDatePage/Create"))
  },
  {
    path: "showtime",
    component: asyncComponent(() => import("../ShowtimePage"))
  },
  {
    path: "showtime/create",
    component: asyncComponent(() => import("../ShowtimePage/Create"))
  },
  {
    path: "showtime/edit/:uuid",
    component: asyncComponent(() => import("../ShowtimePage/Edit"))
  },
  {
    path: "attributes",
    component: asyncComponent(() => import("../AttributePage"))
  },
  {
    path: "statictext",
    component: asyncComponent(() => import("../StatictextPage"))
  },
  {
    path: "statictext/edit/:uuid",
    component: asyncComponent(() => import("../StatictextPage/Edit"))
  },
  {
    path: "statictext/create",
    component: asyncComponent(() => import("../StatictextPage/Create"))
  },
  {
    path: "garnish",
    component: asyncComponent(() => import("../GarnishPage"))
  },
  {
    path: "garnish/edit/:uuid",
    component: asyncComponent(() => import("../GarnishPage/Edit"))
  },
  {
    path: "garnish/create",
    component: asyncComponent(() => import("../GarnishPage/Create"))
  },
  {
    path: "category",
    component: asyncComponent(() => import("../CategoryPage"))
  },
  {
    path: "category/edit/:uuid",
    component: asyncComponent(() => import("../CategoryPage/Edit"))
  },
  {
    path: "category/create",
    component: asyncComponent(() => import("../CategoryPage/Create"))
  },
  {
    path: "payment",
    component: asyncComponent(() => import("../PaymentPage"))
  },
  {
    path: "cash_register",
    component: asyncComponent(() => import("../CashRegisterPage"))
  },
  {
    path: "cash_register/create",
    component: asyncComponent(() => import("../CashRegisterPage/Create"))
  },
  {
    path: "cash_register/edit/:uuid",
    component: asyncComponent(() => import("../CashRegisterPage/Edit"))
  },
  {
    path: "openingHour",
    component: asyncComponent(() => import("../OpeningHourPage"))
  },
  {
    path: "ingredientAdded",
    component: asyncComponent(() => import("../IngredientAddedPage"))
  },
  {
    path: "ingredientAdded/edit/:uuid",
    component: asyncComponent(() => import("../IngredientAddedPage/Edit"))
  },
  {
    path: "ingredientAdded/create",
    component: asyncComponent(() => import("../IngredientAddedPage/Create"))
  },

  {
    path: "ingredientIncluded",
    component: asyncComponent(() => import("../IngredientIncludedPage"))
  },
  {
    path: "ingredientIncluded/edit/:uuid",
    component: asyncComponent(() => import("../IngredientIncludedPage/Edit"))
  },
  {
    path: "ingredientIncluded/create",
    component: asyncComponent(() => import("../IngredientIncludedPage/Create"))
  },

  {
    path: "menuold",
    component: asyncComponent(() => import("../MenuOldPage"))
  },

  {
    path: "menu",
    component: asyncComponent(() => import("../MenuPage"))
  },
  {
    path: "menu/create",
    component: asyncComponent(() => import("../MenuPage/Create"))
  },
  {
    path: "menu/edit/:uuid",
    component: asyncComponent(() => import("../MenuPage/Edit"))
  },
  {
    path: "menu/show/:uuid",
    component: asyncComponent(() => import("../MenuPage/Show"))
  },
  {
    path: "product/active",
    component: asyncComponent(() => import("../ProductPage/Active"))
  },
  {
    path: "product/create_from_base",
    component: asyncComponent(() => import("../ProductPage/CreateFromBase"))
  },
  {
    path: "product",
    component: asyncComponent(() => import("../ProductPage"))
  },
  {
    path: "product/edit/:uuid",
    component: asyncComponent(() => import("../ProductPage/Edit"))
  },
  {
    path: "product/create",
    component: asyncComponent(() => import("../ProductPage/Create"))
  },
  {
    path: "api/api",
    component: asyncComponent(() => import("../Api/ApiPage"))
  },
  {
    path: "api/payment",
    component: asyncComponent(() => import("../Api/PaymentPage"))
  },
  {
    path: "api/users",
    component: asyncComponent(() => import("../Api/UsersPage"))
  },
  {
    path: "qrcode",
    component: asyncComponent(() => import("../QrcodePage"))
  },
  {
    path: "promocode",
    component: asyncComponent(() => import("../PromocodePage"))
  },
  {
    path: "homeIcon",
    component: asyncComponent(() => import("../HomeIconPage"))
  },
  {
    path: "table",
    component: asyncComponent(() => import("../TablePage"))
  },
  {
    path: "options",
    component: asyncComponent(() => import("../OptionsPage"))
  },

  {
    path: "options/create",
    component: asyncComponent(() => import("../OptionsPage/Create"))
  },
  {
    path: "options/edit/:uuid",
    component: asyncComponent(() => import("../OptionsPage/Edit"))
  },
  {
    path: "options/edit/:uuid/show",
    component: asyncComponent(() => import("../OptionsPage/Show"))
  },
  {
    path: "optionValue/edit/:optionUuid/:optionValueId",
    component: asyncComponent(() => import("../OptionsPage/OptionValue/Edit"))
  },
  {
    path: "optionValue/create/:optionUuid",
    component: asyncComponent(() => import("../OptionsPage/OptionValue/Create"))
  },

  {
    path: "formula",
    component: asyncComponent(() => import("../FormulaPage"))
  },
  {
    path: "formula/edit/:uuid",
    component: asyncComponent(() => import("../FormulaPage/Edit"))
  },
  {
    path: "formula/create",
    component: asyncComponent(() => import("../FormulaPage/Create"))
  },

  {
    path: "favicon",
    component: asyncComponent(() => import("../FaviconPage"))
  },

  {
    path: "logo",
    component: asyncComponent(() => import("../LogoPage"))
  },

  {
    path: "logodark",
    component: asyncComponent(() => import("../LogodarkPage"))
  },

  {
    path: "logo_square",
    component: asyncComponent(() => import("../LogoSquarePage"))
  },
  {
    path: "logo_print",
    component: asyncComponent(() => import("../LogoPrintPage"))
  },
  {
    path: "img_layout",
    component: asyncComponent(() => import("../ImgLayoutPage"))
  },

  {
    path: "media",
    component: asyncComponent(() => import("../MediaPage"))
  },

  {
    path: "media/create",
    component: asyncComponent(() => import("../MediaPage/Create"))
  },

  {
    path: "media/edit/:mediaId",
    component: asyncComponent(() => import("../MediaPage/Edit"))
  },

  {
    path: "mediaV2",
    component: asyncComponent(() => import("../MediaV2Page"))
  },

  {
    path: "mediaV2/create",
    component: asyncComponent(() => import("../MediaV2Page/Create"))
  },

  {
    path: "mediaV2/edit/:mediaId",
    component: asyncComponent(() => import("../MediaV2Page/Edit"))
  },



  {
    path: "pricing",
    component: asyncComponent(() => import("../PricingPage"))
  },
  {
    path: "pricing/create",
    component: asyncComponent(() => import("../PricingPage/Create"))
  },
  {
    path: "pricing/show/:uuid",
    component: asyncComponent(() => import("../PricingPage/Show"))
  },
  {
    path: "pricing/edit/:uuid",
    component: asyncComponent(() => import("../PricingPage/Edit"))
  },
  {
    path: "pricingValue/edit/:pricingValueId",
    component: asyncComponent(() => import("../PricingPage/PricingValue/Edit"))
  },
  {
    path: "pricingValue/create/:pricingUuid",
    component: asyncComponent(() =>
      import("../PricingPage/PricingValue/Create")
    )
  },
  {
    path: "printing",
    component: asyncComponent(() => import("../PrintingPage"))
  },
  {
    path: "printing/show/:uuid",
    component: asyncComponent(() => import("../PrintingPage/Show"))
  },
  {
    path: "printing/edit/:uuid",
    component: asyncComponent(() => import("../PrintingPage/Edit"))
  },
  {
    path: "printing/create",
    component: asyncComponent(() => import("../PrintingPage/Create"))
  },

  {
    path: "template",
    component: asyncComponent(() => import("../TemplatePage"))
  },
  {
    path: "template/create",
    component: asyncComponent(() => import("../TemplatePage/Create"))
  },
  {
    path: "template/edit/:uuid",
    component: asyncComponent(() => import("../TemplatePage/Edit"))
  },
  {
    path: "firestore",
    component: asyncComponent(() => import("../FirestorePage"))
  },
  {
    path: "zone",
    component: asyncComponent(() => import("../VenuePage"))
  },
  {
    path: "zone/create",
    component: asyncComponent(() => import("../VenuePage/Create"))
  },
  {
    path: "zone/edit/:uuid",
    component: asyncComponent(() => import("../VenuePage/Edit"))
  },
  {
    path: "zone/show/:uuid",
    component: asyncComponent(() => import("../VenuePage/Show"))
  },
  {
    path: "userInfo",
    component: asyncComponent(() => import("../User/InfoPage"))
  },
  {
    path: "userLogin",
    component: asyncComponent(() => import("../User/LoginPage"))
  },
  {
    path: "designProduct",
    component: asyncComponent(() => import("../Design/ProductPage"))
  },
  {
    path: "statusOrder",
    component: asyncComponent(() => import("../Order/StatusPage"))
  },
  {
    path: "bookingOrder",
    component: asyncComponent(() => import("../Booking/StatusPage"))
  },
  {
    path: "export/order",
    component: asyncComponent(() => import("../Export/Order"))
  },
  {
    path: "export/orderProduct",
    component: asyncComponent(() => import("../Export/OrderProduct"))
  },
  {
    path: "export/orderMonthly",
    component: asyncComponent(() => import("../Export/OrderMonthly"))
  },
  {
    path: "export/user",
    component: asyncComponent(() => import("../Export/User"))
  },
  {
    path: "export/product",
    component: asyncComponent(() => import("../Export/Product"))
  },
  {
    path: "export/tip",
    component: asyncComponent(() => import("../Export/Tip"))
  },
  {
    path: "cookie",
    component: asyncComponent(() => import("../CookiePage"))
  },
  {
    path: "traduction",
    component: asyncComponent(() => import("../TraductionPage"))
  },
  {
    path: "traduction/create",
    component: asyncComponent(() => import("../TraductionPage/Create"))
  },


  {
    path: "traduction/edit/:uuid",
    component: asyncComponent(() => import("../TraductionPage/Edit"))
  },






  {
    path: "apptv",
    component: asyncComponent(() => import("../AppTv"))
  },
  {
    path: "restock",
    component: asyncComponent(() => import("../Restock"))
  },
  {
    path: "restock/create",
    component: asyncComponent(() => import("../Restock/Create"))
  },
  {
    path: "restock/edit/:id",
    component: asyncComponent(() => import("../Restock/Edit"))
  },
  {
    path: "restock/show/:id",
    component: asyncComponent(() => import("../Restock/Show"))
  },
  {
    path: "restock_slot/:id/create",
    component: asyncComponent(() => import("../RestockSlot/Create"))
  },
  {
    path: "restock_slot/:restock_id/edit/:restock_slot_id",
    component: asyncComponent(() => import("../RestockSlot/Edit"))
  },




];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;

    //console.log(12345);
    //console.log(url);

    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;

          //console.log(otherProps);

          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}



      </div>
    );
  }
}

export default AppRouter;
