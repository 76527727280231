import { all, call, takeEvery, put, select, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getToken, clearToken, clearRestaurantId } from "helpers/utility";
import actions from "./actions";
import actionsUser from "../user/actions";
import request from "helpers/request";
import getCurrentRestaurantUuid from "helpers/getCurrentRestaurantUuid";
import * as Sentry from "@sentry/react";

const fakeApiCall = true; // auth0 or express JWT

const getState = state => state;
const getRouter = state => state.router;

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {

    const result = yield call(request.login, payload);

    if (result) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: result,
        profile: "Profile"
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem("id_token", payload.token);


    //console.log(1111111);

    const result = yield call(request.getUser);

    yield put({ type: actionsUser.SET_USER, payload: result.data });

    yield put({ type: "INIT", payload: {} });

    const state = yield select(getState);

    Sentry.setContext("character", {
      lastname: result.data.lastname,
      firstname: result.data.firstname,
      email: result.data.email,
    });




    if (getCurrentRestaurantUuid()) {

      //console.log(222222);


      if (state.router.location.pathname === '/reset_password') {
        return;
      }
      if (state.router.location.pathname === '/forgotpassword') {
        return;
      }

      if (state.router.location.pathname === "/" || state.router.location.pathname === "/signin") {
        document.location.href = '/dashboard';
        //yield put(push("/dashboard"));
      } else {
        yield put(push(state.router.location.pathname));
      }
    } else {
      yield put(push("/dashboard/setting"));
    }

    /*
    if (state.router.location.pathname === "/") {

    } else {
      yield put(push(state.router.location.pathname));
    }*/
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken();
    clearRestaurantId();
    yield put(push("/"));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {


    const token = getToken().get("idToken");
    console.log(12345678);
    console.log(token);
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: "Profile"
      });
    }
  });
}

export function* forgetRequest() {
  yield takeEvery("FORGET_REQUEST", function* ({ payload }) {

    /*
        const result = yield call(request.login, payload);

        if(result) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                token: result,
                profile: 'Profile',
            });
        } else {
            yield put({ type: actions.LOGIN_ERROR });
        }
        */
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(forgetRequest)
  ]);
}
