import { getDefaultPath } from "helpers/urlSync";
import actions, { getView } from "./actions";

const preKeys = getDefaultPath();

const initState = {
  collapsed: window.innerWidth > 1220 ? false : true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  fetching: false,
  ready: false,
  searchProduct: '',
  searchCategory: '',
  searchIngredient: '',
  searchOption: '',
  searchTable: '',
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.COLLPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed
      };
    case 'APP_READY':
      return {
        ...state,
        ready: true
      };
    case actions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer
      };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current
      };
    case actions.FETCHING:
      return {
        ...state,
        fetching: action.payload
      };

    case actions.SEARCH_OPTION:
      return {
        ...state,
        searchOption: action.payload
      };

    case actions.SEARCH_TABLE: {
      console.log(7654);
      return {
        ...state,
        searchTable: action.payload

      }
    };

    case actions.SEARCH_PRODUCT:
      return {
        ...state,
        searchProduct: action.payload
      };

    case actions.SEARCH_CATEGORY:
      return {
        ...state,
        searchCategory: action.payload
      };

    case actions.SEARCH_INGREDIENT:
      return {
        ...state,
        searchIngredient: action.payload
      };

    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        current: []
      };
    default:
      return state;
  }
  return state;
}
