import { SET_SERVICES } from "../restaurant/constants";

const initState = [];

export default function serviceReducer(state = initState, action) {
  switch (action.type) {
    case SET_SERVICES:
      return action.payload;

    default:
      return state;
  }
}
