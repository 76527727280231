import { SET_TAX } from "../restaurant/constants";

const initState = [];

export default function taxReducer(state = initState, action) {
  switch (action.type) {
    case SET_TAX:

      return action.payload;

    default:
      return state;
  }
}
