export default function hasRole(roles, role) {


    const match = roles.find((o) => o.name === role);

    if (match) {
        return true;
    }


    return false;
}
