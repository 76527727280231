import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Layout } from "antd";
import appActions from "redux/app/actions";
import TopbarUser from "./topbarUser";
import TopbarLang from "./TopbarLang";
import TopbarWrapper from "./topbar.style";
import themes from "settings/themes";
import { themeConfig } from "settings";
import isInApp from "../../helpers/isInApp";
import { apiUrl } from "settings";


const { Header } = Layout;
const { toggleCollapsed } = appActions;
const customizedTheme = themes[themeConfig.theme];



class Topbar extends Component {




  render() {
    const { toggleCollapsed } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };


    //console.log(8765);
    //console.log(this.props.restaurantInfo);

    const url = `${apiUrl}/restaurant/${this.props.restaurantInfo.uuid}/command/json`;


    if (isInApp() === true) {
      return false;
    }
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">

            {
              isInApp() === false && (
                <button
                  className={
                    collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
                  }
                  style={{ color: customizedTheme.textColor }}
                  onClick={toggleCollapsed}
                />
              )
            }

          </div>



          <ul className="isoRight">
            <li
              onClick={() => this.setState({ selectedItem: "flag" })}
              className="isoUser"
            >
              <TopbarLang />
            </li>

            <li
              className="isoUser"
            >
              <a href={url} target="_blank" type="primary">
                <Icon type="sync" />
              </a>
            </li>

            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}



export default connect(
  state => ({
    ...state.App,
    restaurantInfo: state.RestaurantInfo
  }),
  { toggleCollapsed }
)(Topbar);
