import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from 'components/uielements/popover';
import IntlMessages from 'components/utility/intlMessages';
import { Link } from 'react-router-dom';
import authAction from 'redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import getInitials from 'helpers/getInitials';
import { Avatar } from 'antd';
import translateMsg from "helpers/translateMsg";
import { withTranslation } from 'react-i18next';

const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    const { user } = this.props;

    if (!user) {
      return false;
    }

    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">

        <div style={{ display: 'none' }}>
          <a className="isoDropdownLink" href="# ">

            {translateMsg(this.props.t, 'back', 'back_ui_feedback')}
          </a>
          <a className="isoDropdownLink" href="# ">
            {translateMsg(this.props.t, 'back', 'back_ui_help')}

          </a>
        </div>
        <a className="isoDropdownLink" href="# ">
          <Link to="/dashboard/setting">
            {translateMsg(this.props.t, 'webapp', 'pwa_ui__settings')}
          </Link>
        </a>
        <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
          {translateMsg(this.props.t, 'webapp', 'pwa_ui__logout')}
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">

          <Avatar>
            {getInitials(user.name).toUpperCase()}
          </Avatar>

          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    user: state.User,
  }
};

export default connect(mapStateToProps, { logout })(withTranslation()(TopbarUser));



