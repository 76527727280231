import antdSA from 'antd/lib/locale-provider/nl_NL';
import appLocaleData from 'react-intl/locale-data/nl';
import saMessages from '../locales/nl_NL.json';

const saLang = {
  messages: {
    ...saMessages
  },
  antd: antdSA,
  locale: 'nl-NL',
  data: appLocaleData
};
export default saLang;
