import {

  INIT,
  LOAD_RESTAURANT_INFO,
  UPDATE_RESTAURANT_INFO,
  ADD_RESTAURANT_PRODUCT,
  DELETE_RESTAURANT_PRODUCT,
  UPDATE_RESTAURANT_PRODUCT,
  ADD_RESTAURANT_SHOWTIME,
  DELETE_RESTAURANT_SHOWTIME,
  UPDATE_RESTAURANT_SHOWTIME,
  DELETE_RESTAURANT_OPTION,
  ADD_RESTAURANT_OPTION,
  UPDATE_RESTAURANT_OPTION,
  DELETE_RESTAURANT_OPTION_VALUE,
  ADD_RESTAURANT_OPTION_VALUE,
  UPDATE_RESTAURANT_OPTION_VALUE,
  SET_RESTAURANT_PRICINGS,
  SET_RESTAURANT_REGIMES,
  SET_RESTAURANT_PAYMENTS,
  SET_RESTAURANT_LANGUAGES,
  SET_RESTAURANT_MENUS,
  UPLOAD_IMG_LAYOUT,
  UPLOAD_IMG_CATALOG,
  UPDATE_RESTAURANT_MENU,
  SET_RESTAURANT_PRODUCTS
} from "./constants";

const actions = {

  uploadImgLayout: payload => ({
    type: UPLOAD_IMG_LAYOUT,
    payload
  }),

  uploadImgCatalog: payload => ({
    type: UPLOAD_IMG_CATALOG,
    payload
  }),



  init: () => ({
    type: INIT
  }),
  loadRestaurantInfo: () => ({
    type: LOAD_RESTAURANT_INFO
  }),
  updateRestaurantInfo: payload => ({
    type: UPDATE_RESTAURANT_INFO,
    payload
  }),
  addRestaurantProduct: payload => ({
    type: ADD_RESTAURANT_PRODUCT,
    payload
  }),
  deleteRestaurantProduct: payload => ({
    type: DELETE_RESTAURANT_PRODUCT,
    payload
  }),
  updateRestaurantProduct: payload => ({
    type: UPDATE_RESTAURANT_PRODUCT,
    payload
  }),
  addRestaurantShowtime: payload => ({
    type: ADD_RESTAURANT_SHOWTIME,
    payload
  }),
  deleteRestaurantShowtime: payload => ({
    type: DELETE_RESTAURANT_SHOWTIME,
    payload
  }),
  updateShowtime: payload => ({
    type: UPDATE_RESTAURANT_SHOWTIME,
    payload
  }),

  deleteRestaurantOption: payload => ({
    type: DELETE_RESTAURANT_OPTION,
    payload
  }),
  addRestaurantOption: payload => ({
    type: ADD_RESTAURANT_OPTION,
    payload
  }),
  updateRestaurantOption: payload => ({
    type: UPDATE_RESTAURANT_OPTION,
    payload
  }),
  deleteRestaurantOptionValue: payload => ({
    type: DELETE_RESTAURANT_OPTION_VALUE,
    payload
  }),
  addRestaurantOptionValue: payload => ({
    type: ADD_RESTAURANT_OPTION_VALUE,
    payload
  }),
  updateRestaurantOptionValue: payload => ({
    type: UPDATE_RESTAURANT_OPTION_VALUE,
    payload
  }),
  setRestaurantPricing: payload => ({
    type: SET_RESTAURANT_PRICINGS,
    payload
  }),

  setRestaurantRegime: payload => ({
    type: SET_RESTAURANT_REGIMES,
    payload
  }),

  setRestaurantLanguages: payload => ({
    type: SET_RESTAURANT_LANGUAGES,
    payload
  }),

  setRestaurantPayments: payload => ({
    type: SET_RESTAURANT_PAYMENTS,
    payload
  }),

  setRestaurantMenus: payload => ({
    type: SET_RESTAURANT_MENUS,
    payload
  }),
  updateRestaurantMenu: payload => ({
    type: UPDATE_RESTAURANT_MENU,
    payload
  }),


  setRestaurantProducts: payload => ({
    type: SET_RESTAURANT_PRODUCTS,
    payload
  }),

};




export default actions;
